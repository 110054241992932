import React from 'react'
import './contato.css'

import {AiTwotoneMail, AiOutlineWhatsApp} from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contato = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7d81uu9', 'template_h9oqqin', form.current, '4P7xX_vZ4nKG7sXK6')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()  

    }

  return (
    <section id='contato'>
      <h5>Entre em Contato</h5>
      <h2>Contato</h2>

      <div className='container contato_container'>
        <div className='contato_opcoes'>

          <article className='contato_opcao'>
            <AiTwotoneMail className='contato_opcao-icon'/>
            <h4>Email</h4>
            <h5>lucasjacintodosantos@gmail.com</h5>
            <a href="mailto:lucasjacintodosantos@gmail.com" target='_blank' rel="noreferrer">Mande um email</a>
          </article>

          <article className='contato_opcao'>
            <AiOutlineWhatsApp className='contato_opcao-icon'/>
            <h4>WhatsApp</h4>
            <h5>62 98629-4238</h5>
            <a href="https://contate.me/lucasjacinto" target='_blank' rel="noreferrer">Mande uma mensagem</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Seu Nome Completo' required/>
          <input type="mail" name='email' placeholder='Seu Email' required/>
          <textarea name='mensagem' rows='7' placeholder='Sua Mensagem' required></textarea>
          <button type='submit' className='btn btn-primary'>Enviar</button>
        </form>
      </div>
    </section>
  )
}

export default Contato