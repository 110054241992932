import React from 'react'
import {AiFillLinkedin,AiFillGithub} from 'react-icons/ai'

const Media = () => {
  return (
    <div className='header__socials'>
      <a href='https://www.linkedin.com/in/lucas-jacinto-dos-santos-607216244/' target='_blank' rel="noreferrer"><AiFillLinkedin/></a>
      <a href='https://github.com/lucas-jacinto' target='_blank' rel="noreferrer"><AiFillGithub/></a>
    </div>
  )
}

export default Media