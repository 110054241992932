import React from 'react'
import './experiencia.css'
import { AiFillHtml5,AiFillGithub} from 'react-icons/ai'
import {BsFillBootstrapFill} from 'react-icons/bs'
import {DiCss3Full, DiReact, DiPython, DiNodejsSmall} from 'react-icons/di'
import {IoLogoJavascript} from 'react-icons/io'
import {SiTailwindcss} from 'react-icons/si'
import {FiFigma} from 'react-icons/fi'


const Experiencia = () => {
  return (
    <section id='experiencia'>
    <h5>Skills que possuo</h5>
    <h2>Minha Experiencia</h2>

    <div className='container experiencia_container'>
      <div className='experiencia_frontend'>
        <h3>Front-End</h3>
        <div className="experiencia_content">

          <article className='experiencia_detalhes'>
            <AiFillHtml5 className='experiencia_detalhes-icons'/>
            <div>
              <h4>HTML</h4>
              <small className='text-light'>Avançado</small>
            </div>
            
          </article>
          <article className='experiencia_detalhes'>
            <DiCss3Full className='experiencia_detalhes-icons'/>
            <div>
              <h4>CSS</h4>
              <small className='text-light'>Avançado</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <IoLogoJavascript className='experiencia_detalhes-icons'/>
            <div>
              <h4>JavaScript</h4>
              <small className='text-light'>Avançado</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <DiReact className='experiencia_detalhes-icons'/>
            <div>
              <h4>React</h4>
              <small className='text-light'>Avançado</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <SiTailwindcss className='experiencia_detalhes-icons'/>
            <div>
              <h4>TailWind</h4>
              <small className='text-light'>Intermediário</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <BsFillBootstrapFill className='experiencia_detalhes-icons'/>
            <div>
              <h4>Bootstrap</h4>
              <small className='text-light'>Intermediário</small>
            </div>
          </article>
        </div>
      </div>

      <div className='experiencia_outros'>
      <h3>Outros</h3>
        <div className="experiencia_content">

          <article className='experiencia_detalhes'>
            <AiFillGithub className='experiencia_detalhes-icons'/>
            <div>
              <h4>Git/GitHub</h4>
              <small className='text-light'>Avançado</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <DiPython className='experiencia_detalhes-icons'/>
            <div>
              <h4>Python</h4>
              <small className='text-light'>Básico</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <DiNodejsSmall className='experiencia_detalhes-icons'/>
            <div>
              <h4>Node.js</h4>
              <small className='text-light'>Básico</small>
            </div>
          </article>

          <article className='experiencia_detalhes'>
            <FiFigma  className='experiencia_detalhes-icons' />
            <div>
              <h4>Figma</h4>
              <small className='text-light'>Intermediário</small>
            </div>
          </article>
        </div>
      </div>
      </div>
   

    </section>
  )
}

export default Experiencia