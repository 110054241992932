import React from 'react'
import './nav.css'

import {AiOutlineUser, AiOutlineMessage} from 'react-icons/ai'
import {BiBookBookmark} from 'react-icons/bi'
import {MdMiscellaneousServices} from 'react-icons/md'
import {HiOutlineHome} from 'react-icons/hi'

import {useState} from 'react'

const Nav = () => {
  const [activeNav, setActiveNav ] = useState('#')
  return (
    <nav>
      <a href='#experiencia' onClick={() => setActiveNav('#experiencia')} className={activeNav === '#experiencia' ? 'active' : ''}><BiBookBookmark/></a>

      <a href='#sobre' onClick={() => setActiveNav('#sobre')} className={activeNav === '#sobre' ? 'active' : ''}> <AiOutlineUser/></a>

      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><HiOutlineHome/></a>

      <a href='#servicos' onClick={() => setActiveNav('#servicos')} className={activeNav === '#servicos' ? 'active' : ''}><MdMiscellaneousServices/></a>

      <a href='#contato' onClick={() => setActiveNav('#contato')} className={activeNav === '#contato' ? 'active' : ''}><AiOutlineMessage/></a>
    </nav>
  )
}

export default Nav