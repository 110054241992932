import React from 'react'
import './fedbacks.css'
import cl1 from '../../assets/cl1.png'
import cl2 from '../../assets/cl2.jpg'
import cl3 from '../../assets/cl3.png'
import cl4 from '../../assets/cl4.jpg'

import {Pagination} from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";


const Fedbacks = () => {
  return (
    <section id='fedbacks'>
      <h5>Avaliacoes dos Clientes</h5>
      <h2>Fedbacks</h2>

      <Swiper className='container fedback_container' pagination={{clickable:true}} modules={[Pagination]} spaceBetween={40} slidesPerView={1}>
        <SwiperSlide className='fedback'>
          <div className='cliente_avatar'>
            <img src={cl1} alt="avatar do cliente" />
          </div>
          <h5 className='cliente_name'>Joao Paulo</h5>
            <small className='cliente_review'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae odit sint consequuntur reiciendis hic! Sint, delectus maxime dolor quidem placeat animi pariatur accusantium labore consequuntur reprehenderit eum tenetur ratione dolore!
            </small>
        </SwiperSlide>

        <SwiperSlide className='fedback'>
          <div className='cliente_avatar'>
            <img src={cl2} alt="avatar do cliente" />
          </div>
          <h5 className='cliente_name'>Ana Paula</h5>
            <small className='cliente_review'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae odit sint consequuntur reiciendis hic! Sint, delectus maxime dolor quidem placeat animi pariatur accusantium labore consequuntur reprehenderit eum tenetur ratione dolore!
            </small>
        </SwiperSlide>

        <SwiperSlide className='fedback'>
          <div className='cliente_avatar'>
            <img src={cl3} alt="avatar do cliente" />
          </div>
          <h5 className='cliente_name'>Maria da Silva</h5>
            <small className='cliente_review'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae odit sint consequuntur reiciendis hic! Sint, delectus maxime dolor quidem placeat animi pariatur accusantium labore consequuntur reprehenderit eum tenetur ratione dolore!
            </small>
        </SwiperSlide>

        <SwiperSlide className='fedback'>
          <div className='cliente_avatar'>
            <img src={cl4} alt="avatar do cliente" />
          </div>
          <h5 className='cliente_name'>Pedro Santos</h5>
            <small className='cliente_review'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae odit sint consequuntur reiciendis hic! Sint, delectus maxime dolor quidem placeat animi pariatur accusantium labore consequuntur reprehenderit eum tenetur ratione dolore!
            </small>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default Fedbacks