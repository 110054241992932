import React from 'react'
import './footer.css'

import {AiFillLinkedin,AiFillGithub,AiOutlineInstagram} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer_logo'>LOGO</a>

    <ul className='fotlinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#sobre">Sobre</a></li>
      <li><a href="#servicos">Serviços</a></li>
      <li><a href="#experiencia">Experiência</a></li>
      <li><a href="#fedbacks">Fedbacks</a></li>
      <li><a href="#contato">Contato</a></li>
    </ul>

    <div className='footer_socials'>
      <a href="https://www.instagram.com/universolj/"><AiOutlineInstagram /></a>
      <a href="https://www.linkedin.com/in/lucas-jacinto-dos-santos-607216244/"><AiFillLinkedin/></a>
      <a href="https://github.com/lucas-jacinto"><AiFillGithub/></a>
    </div>

    <div className='footer_copyright'>
      <small>&copy; Copyright by Lucas Jacinto dos Santos. All rights reserved.</small>
    </div>
    </footer>
  )
}

export default Footer