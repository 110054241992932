import React from 'react'
import './servicos.css'
import {BsCheck} from 'react-icons/bs'

const Servicos = () => {
  return (
    <section id='servicos'>
      <h5>O que eu ofereco</h5>
      <h2>Serviços</h2>

      <div className='container servicos_container'>
        <article className='servico'>
          <div className='servico_head'>
              <h3>Manutenção</h3>
          </div>

          <ul className='servico_lista'>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
          </ul>
        </article>

        <article className='servico'>
          <div className='servico_head'>
              <h3>UI/UX</h3>
          </div>

          <ul className='servico_lista'>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
          </ul>
        </article>

        <article className='servico'>
          <div className='servico_head'>
              <h3>Landing Pages</h3>
          </div>

          <ul className='servico_lista'>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
            <li> 
              <BsCheck className='servico_lista-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Servicos