import React from 'react'
import './header.css'

import CTA from './CTA'
import foto from '../../assets/foto.jpeg'
import Media from './Media'



const Header = () => {
  return (
   <header>
    <div className='container header__container'>
      <h5>Prazer, me chamo</h5>
      <h1>Lucas Jacinto</h1>
      <h5 className="text-light">
        Desenvolvedor Front-End
      </h5>
      <div className='foto'>
        <img src={foto} alt='lucas perfil foto'></img>
      </div>
      <Media/>
      <CTA/>
      <a href='#contato' className='scroll_down'>&darr;</a>
    </div>
   </header>
  )
}

export default Header