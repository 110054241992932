import React from 'react'
import './sobre.css'
import foto from '../../assets/foto.jpeg'

import {FaAward} from 'react-icons/fa'
import {TbUsers} from 'react-icons/tb'

const Sobre = () => {
  return (
   <section id='sobre'>
    <h2>Get To Know</h2>
    <h5>Sobre mim</h5>

    <div className='container sobre_container'>
      <div className='sobre_mim'>
        <div className='sobre_mim_img'>
        <img src={foto} alt="imagem de perfil do lucas" />
        </div>
      </div>

      <div className='sobre_content'>
        <div className="sobre_cards">

          <article className='sobre_card'>
          <FaAward className='sobre_icon'/>
            <h5>Experiencia</h5>
            <small>1+ Anos </small>
          </article>

          <article className='sobre_card'>
          <FaAward className='sobre_icon'/>
          <h5> Clientes </h5>
          <small>10+ Landings Pages </small>
          </article>

          <article className='sobre_card'>
          <TbUsers className='sobre_icon'/>
          <h5>Projetos</h5>
          <small> 32+ Completos </small>
          </article>
        </div>

        <p>
        Desenvolvedor Web Front-End, estou a 1 ano estudando e aplicando conhecimento em: HTML e CSS, JavaScript, React.js, TaildWind, Sass.
        Fiz alguns serviços de freelancer criando landings pages para clientes do O Novo Mercado e agora estou em busca de uma oportunidade formal em uma empresa séria.
        Sou profissional, técnico e objetivo , não tenho preguiça ou medo de fazer o trabalho bem feito e em assumir responsabilidades.
        Devido a ter trabalhado por 5 anos dando aulas particulares de física e matemática, tenho facilidade na explicação de tópicos e em comunicação. Estou disponível a trabalhar de graça por um período de tempo, se necessário.
        Atualmente cursando uma gradução em engenharia de software e outra graduação em física, sou muito curioso e apaixonado em aprender coisas novas.
        Tenho facilidade em aprender de forma rápida, e total disponibilidade para aprender qualquer tecnologia necessária.
        </p>

        <a href="#contato" className='btn btn-primary'>Vamos Conversar</a>
      </div>
    </div>
   </section>
  )
}

export default Sobre