import React from 'react'
import './portifolio.css'
import lp1 from '../../assets/lp1.png'
import lp2 from '../../assets/lp2.png'
import lp3 from '../../assets/lp3.png'
import lp4 from '../../assets/lp4.png'

const Portifolio = () => {
  return (
    <section id='portifolio'>
      <h5>Meus Projetos Recentes</h5>
      <h2>Portifolio</h2>

      <div className='container portifolio_container'>
        <article className='portifolio_item'>
          <div className='portifolio_item-img'>
            <img src={lp1} alt="site que doces que fiz" />
          </div>
          <h3>Landing Page</h3>
          <div className='portifolio_item-cta'>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn' target='_blank' rel="noreferrer">GitHub</a>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn btn-primary' target='_blank' rel="noreferrer">Preview</a>
          </div>
        </article>

        <article className='portifolio_item'>
          <div className='portifolio_item-img'>
            <img src={lp2} alt="site que doces que fiz" />
          </div>
          <h3>Landing Page</h3>
          <div className='portifolio_item-cta'>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn' target='_blank' rel="noreferrer">GitHub</a>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn btn-primary' target='_blank' rel="noreferrer">Preview</a>
          </div>
        </article>

        <article className='portifolio_item'>
          <div className='portifolio_item-img'>
            <img src={lp3} alt="site que doces que fiz" />
          </div>
          <h3>Landing Page</h3>
          <div className='portifolio_item-cta'>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn' target='_blank' rel="noreferrer">GitHub</a>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn btn-primary' target='_blank' rel="noreferrer">Preview</a>
          </div>
        </article>

        <article className='portifolio_item'>
          <div className='portifolio_item-img'>
            <img src={lp4} alt="site que doces que fiz" />
          </div>
          <h3>Landing Page</h3>
          <div className='portifolio_item-cta'>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn' target='_blank' rel="noreferrer">GitHub</a>
            <a href="https://github.com/lucas-jacinto/landingpageGZ" className='btn btn-primary' target='_blank' rel="noreferrer">Preview</a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Portifolio